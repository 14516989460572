var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CardContent } from "@mui/material";
import { Datagrid, TextField } from "react-admin";
var RoadmapTextIcons = function () {
    return (_jsxs(Box, __assign({ sx: { mb: 4 } }, { children: [_jsx(CardContent, { children: _jsx("h2", { children: "Roadmap Text Icons" }) }), _jsxs(Datagrid, { children: [_jsx(TextField, { source: "language", label: "Language" }), _jsx(TextField, { source: "roadmap.stepsArray[0].title", label: "Roadmap Title" }), _jsx(TextField, { source: "roadmap.stepsArray[1].title", label: "Roadmap Title" }), _jsx(TextField, { source: "roadmap.stepsArray[2].title", label: "Roadmap Title" }), _jsx(TextField, { source: "roadmap.stepsArray[3].title", label: "Roadmap Title" }), _jsx(TextField, { source: "roadmap.stepsArray[4].title", label: "Roadmap Title" }), _jsx(TextField, { source: "roadmap.stepsArray[5].title", label: "Roadmap Title" }), _jsx(TextField, { source: "roadmap.stepsArray[6].title", label: "Roadmap Title" }), _jsx(TextField, { source: "roadmap.stepsArray[7].title", label: "Roadmap Title" }), _jsx(TextField, { source: "roadmap.stepsArray[8].title", label: "Roadmap Title" })] })] })));
};
export default RoadmapTextIcons;
