var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { GlobalContainer } from "../assets/styles/mainPageStyles";
import { PlanetBlockHeader, PlanetBlockGradientText } from '../assets/styles/planetBlockV2/planetBlock.style';
import Header from "../components/mainPage/header/Header";
import CanvasScene from "../components/globe/CanvasScene";
var NotFoundPage = function (_a) {
    var currentLocale = _a.currentLocale, onLocaleChange = _a.onLocaleChange;
    return (_jsxs(GlobalContainer, { children: [_jsx(Header, { currentLocale: currentLocale, onLocaleChange: onLocaleChange }), _jsxs("div", __assign({ style: {
                    marginTop: "50px"
                } }, { children: [_jsx(PlanetBlockHeader, { children: "NOT FOUND" }), _jsx(PlanetBlockGradientText, { children: "404" }), _jsx("div", __assign({ style: {
                            margin: '50px auto',
                            width: '347px',
                            height: '347px',
                        } }, { children: _jsx(CanvasScene, { x: 0, y: 0, z: 0, color: 0x411C1F }) }))] }))] }));
};
export default NotFoundPage;
