var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Edit, SimpleForm, FileInput, FileField } from 'react-admin';
var acceptTypes = {
    'image/webp': ['.webp'],
};
export var ImageEdit = function (props) { return (_jsx(Edit, __assign({}, props, { children: _jsx(SimpleForm, { children: _jsx(FileInput, __assign({ source: "file", label: "Upload Image", accept: acceptTypes }, { children: _jsx(FileField, { source: "src", title: "filename" }) })) }) }))); };
