var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CardContent } from "@mui/material";
import { Datagrid, TextField } from "react-admin";
var FooterNavLinkLink = function () {
    return (_jsxs(Box, __assign({ sx: { mb: 4 } }, { children: [_jsx(CardContent, { children: _jsx("h2", { children: "Footer NavLink Link" }) }), _jsxs(Datagrid, __assign({ rowClick: "edit" }, { children: [_jsx(TextField, { source: "language", label: "Language" }), _jsx(TextField, { source: "footer.links.ecosystem.href", label: "Footer Link1" }), _jsx(TextField, { source: "footer.links.development.href", label: "Footer Link2" }), _jsx(TextField, { source: "footer.links.blog.href", label: "Footer Link3" }), _jsx(TextField, { source: "footer.links.learn.href", label: "Footer Link4" }), _jsx(TextField, { source: "footer.links.docs.href", label: "Footer Link5" }), _jsx(TextField, { source: "footer.links.github.href", label: "Footer Link6" }), _jsx(TextField, { source: "footer.links.auditReport.href", label: "Footer Link7" }), _jsx(TextField, { source: "footer.links.mediaKit.href", label: "Footer Link8" }), _jsx(TextField, { source: "footer.links.privacyPolicy.href", label: "Footer Link9" }), _jsx(TextField, { source: "footer.links.terms.text", label: "Footer Link10" }), _jsx(TextField, { source: "footer.links.cookiePolicy.href", label: "Footer Link11" }), _jsx(TextField, { source: "footer.links.contactUs.href", label: "Footer Link12" })] }))] })));
};
export default FooterNavLinkLink;
