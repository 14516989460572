var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import axios from 'axios';
import { getAuthHeaders, refreshAccessToken } from './authProvider';
var apiUrl = process.env.REACT_APP_API_URL;
var dataProvider = {
    uploadImage: function (resource, params) { return __awaiter(void 0, void 0, void 0, function () {
        var formData, response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    formData = new FormData();
                    formData.append('file', params.data.file);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.post("".concat(apiUrl, "/images/upload"), formData, {
                            headers: __assign({ 'Content-Type': 'multipart/form-data' }, getAuthHeaders()),
                        })];
                case 2:
                    response = _a.sent();
                    return [2 /*return*/, { data: __assign(__assign({}, response.data), { id: response.data.filename }) }];
                case 3:
                    error_1 = _a.sent();
                    console.error('Image upload failed:', error_1);
                    throw new Error('Image upload failed');
                case 4: return [2 /*return*/];
            }
        });
    }); },
    getList: function (resource, params) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b, page_1, _c, perPage_1, _d, _e, field, _f, order, response, data, error_2, _g, _h, page, _j, perPage, response, data, error_3;
        return __generator(this, function (_k) {
            switch (_k.label) {
                case 0:
                    if (resource === 'images') {
                        return [2 /*return*/, dataProvider.getListImg(resource, params)];
                    }
                    if (!(resource === 'localization')) return [3 /*break*/, 4];
                    _a = params.pagination || {}, _b = _a.page, page_1 = _b === void 0 ? 1 : _b, _c = _a.perPage, perPage_1 = _c === void 0 ? 10 : _c;
                    _d = params.sort || {}, _e = _d.field, field = _e === void 0 ? 'language' : _e, _f = _d.order, order = _f === void 0 ? 'ASC' : _f;
                    _k.label = 1;
                case 1:
                    _k.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.get("".concat(apiUrl, "/localization"), {
                            headers: getAuthHeaders(),
                            params: {
                                _sort: field,
                                _order: order,
                                _start: (page_1 - 1) * perPage_1,
                                _end: page_1 * perPage_1,
                            },
                        })];
                case 2:
                    response = _k.sent();
                    data = response.data.map(function (item) { return (__assign(__assign({}, item), { id: item.language })); });
                    return [2 /*return*/, {
                            data: data,
                            total: parseInt(response.headers['x-total-count'], 10) || data.length,
                        }];
                case 3:
                    error_2 = _k.sent();
                    console.error('Error fetching localization list:', error_2);
                    throw new Error('Error fetching localization list');
                case 4:
                    if (resource === 'document-Confirm') {
                        return [2 /*return*/, dataProvider.getNotConfirmedUsers()];
                    }
                    _g = params.pagination || {}, _h = _g.page, page = _h === void 0 ? 1 : _h, _j = _g.perPage, perPage = _j === void 0 ? 10 : _j;
                    _k.label = 5;
                case 5:
                    _k.trys.push([5, 7, , 8]);
                    return [4 /*yield*/, axios.get("".concat(apiUrl, "/").concat(resource), {
                            headers: getAuthHeaders(),
                            params: { page: page, limit: perPage },
                        })];
                case 6:
                    response = _k.sent();
                    data = response.data.map(function (item) { return (__assign(__assign({}, item), { id: item._id })); });
                    return [2 /*return*/, {
                            data: data,
                            total: parseInt(response.headers['x-total-count'], 10) || data.length,
                        }];
                case 7:
                    error_3 = _k.sent();
                    console.error('Error fetching list:', error_3);
                    throw new Error('Error fetching list');
                case 8: return [2 /*return*/];
            }
        });
    }); },
    getNotConfirmedUsers: function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, data, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.get("".concat(apiUrl, "/user/not-confirmed-users"), {
                            headers: getAuthHeaders(),
                            params: {
                                isConfirmed: false,
                            },
                        })];
                case 1:
                    response = _a.sent();
                    data = response.data.map(function (user) { return (__assign({}, user)); });
                    return [2 /*return*/, {
                            data: data,
                            total: parseInt(response.headers['x-total-count'], 10) || data.length,
                        }];
                case 2:
                    error_4 = _a.sent();
                    console.error('Error fetching not confirmed users:', error_4);
                    throw new Error('Error fetching not confirmed users');
                case 3: return [2 /*return*/];
            }
        });
    }); },
    getListImg: function (resource, params) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b, page, _c, perPage, response, data, error_5;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _a = params.pagination || {}, _b = _a.page, page = _b === void 0 ? 1 : _b, _c = _a.perPage, perPage = _c === void 0 ? 10 : _c;
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.get("".concat(apiUrl, "/images"), {
                            headers: getAuthHeaders(),
                            params: { page: page, limit: perPage },
                        })];
                case 2:
                    response = _d.sent();
                    data = response.data.map(function (item) { return (__assign(__assign({}, item), { id: item.filename })); });
                    return [2 /*return*/, {
                            data: data,
                            total: parseInt(response.headers['x-total-count'], 10) || data.length,
                        }];
                case 3:
                    error_5 = _d.sent();
                    console.error('Error fetching images:', error_5);
                    throw new Error('Error fetching images');
                case 4: return [2 /*return*/];
            }
        });
    }); },
    getOne: function (resource, params) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_6, response, error_7, response, error_8;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(resource === 'localization')) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.get("".concat(apiUrl, "/localization/").concat(params.id), {
                            headers: getAuthHeaders(),
                        })];
                case 2:
                    response = _a.sent();
                    return [2 /*return*/, { data: __assign(__assign({}, response.data), { id: response.data.language }) }];
                case 3:
                    error_6 = _a.sent();
                    console.error('Error fetching localization item:', error_6);
                    throw new Error('Error fetching localization item');
                case 4:
                    if (!(resource === 'document-Confirm')) return [3 /*break*/, 8];
                    _a.label = 5;
                case 5:
                    _a.trys.push([5, 7, , 8]);
                    return [4 /*yield*/, axios.get("".concat(apiUrl, "/user/get-user-confirm-info/").concat(params.id), {
                            headers: getAuthHeaders(),
                        })];
                case 6:
                    response = _a.sent();
                    return [2 /*return*/, { data: __assign({}, response.data) }];
                case 7:
                    error_7 = _a.sent();
                    console.error('Error fetching localization item:', error_7);
                    throw new Error('Error fetching localization item');
                case 8:
                    _a.trys.push([8, 10, , 11]);
                    return [4 /*yield*/, axios.get("".concat(apiUrl, "/").concat(resource, "/").concat(params.id), {
                            headers: getAuthHeaders(),
                        })];
                case 9:
                    response = _a.sent();
                    return [2 /*return*/, { data: __assign(__assign({}, response.data), { id: response.data._id }) }];
                case 10:
                    error_8 = _a.sent();
                    console.error('Error fetching single item:', error_8);
                    throw new Error('Error fetching single item');
                case 11: return [2 /*return*/];
            }
        });
    }); },
    update: function (resource, params) { return __awaiter(void 0, void 0, void 0, function () {
        var formData, error_9, _a, language, localizationData, response, error_10, response, error_11;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!(resource === 'images')) return [3 /*break*/, 4];
                    formData = new FormData();
                    console.log('Uploading file:', params.data.file);
                    formData.append('file', params.data.file.rawFile);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.put("".concat(apiUrl, "/images/").concat(params.id), formData, {
                            headers: __assign({ 'Content-Type': 'multipart/form-data' }, getAuthHeaders()),
                        })];
                case 2:
                    _b.sent();
                    return [2 /*return*/, { data: __assign(__assign({}, params.data), { id: params.id }) }];
                case 3:
                    error_9 = _b.sent();
                    console.error('Image update failed:', error_9);
                    throw new Error('Image update failed');
                case 4:
                    if (!(resource === 'localization')) return [3 /*break*/, 8];
                    _a = params.data, language = _a.language, localizationData = __rest(_a, ["language"]);
                    if (!language) {
                        throw new Error('Language is required.');
                    }
                    _b.label = 5;
                case 5:
                    _b.trys.push([5, 7, , 8]);
                    return [4 /*yield*/, axios.patch("".concat(apiUrl, "/localization/").concat(language), localizationData, {
                            headers: getAuthHeaders(),
                        })];
                case 6:
                    response = _b.sent();
                    return [2 /*return*/, { data: __assign(__assign({}, response.data), { id: language }) }]; // Use 'language' as the ID
                case 7:
                    error_10 = _b.sent();
                    console.error('Error updating localization item:', error_10);
                    throw new Error('Error updating localization item');
                case 8:
                    _b.trys.push([8, 10, , 11]);
                    return [4 /*yield*/, axios.put("".concat(apiUrl, "/").concat(resource, "/").concat(params.id), params.data, {
                            headers: getAuthHeaders(),
                        })];
                case 9:
                    response = _b.sent();
                    return [2 /*return*/, { data: __assign(__assign({}, response.data), { id: response.data._id || params.id }) }];
                case 10:
                    error_11 = _b.sent();
                    console.error('Error updating item:', error_11);
                    throw new Error('Error updating item');
                case 11: return [2 /*return*/];
            }
        });
    }); },
    create: function (resource, params) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_12, response, error_13;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(resource === 'localization')) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, axios.post("".concat(apiUrl, "/localization"), params.data, {
                            headers: getAuthHeaders(),
                        })];
                case 2:
                    response = _a.sent();
                    return [2 /*return*/, { data: __assign(__assign({}, response.data), { id: response.data.language }) }];
                case 3:
                    error_12 = _a.sent();
                    console.error('Error creating localization item:', error_12);
                    throw new Error('Error creating localization item');
                case 4:
                    _a.trys.push([4, 6, , 7]);
                    return [4 /*yield*/, axios.post("".concat(apiUrl, "/").concat(resource), params.data, {
                            headers: getAuthHeaders(),
                        })];
                case 5:
                    response = _a.sent();
                    return [2 /*return*/, { data: __assign(__assign({}, response.data), { id: response.data._id }) }];
                case 6:
                    error_13 = _a.sent();
                    console.error('Error creating item:', error_13);
                    throw new Error('Error creating item');
                case 7: return [2 /*return*/];
            }
        });
    }); },
    delete: function (resource, params) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_14;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axios.delete("".concat(apiUrl, "/").concat(resource, "/").concat(params.id), {
                            headers: getAuthHeaders(),
                        })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, { data: response.data }];
                case 2:
                    error_14 = _a.sent();
                    console.error('Error deleting item:', error_14);
                    throw new Error('Error deleting item');
                case 3: return [2 /*return*/];
            }
        });
    }); },
    deleteMany: function (resource, params) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            return [2 /*return*/, Promise.resolve({ data: [] })];
        });
    }); },
    getMany: function (resource, params) { return __awaiter(void 0, void 0, Promise, function () {
        return __generator(this, function (_a) {
            throw new Error('Function not implemented.');
        });
    }); },
    getManyReference: function (resource, params) { return __awaiter(void 0, void 0, Promise, function () {
        return __generator(this, function (_a) {
            throw new Error('Function not implemented.');
        });
    }); },
    updateMany: function (resource, params) { return __awaiter(void 0, void 0, Promise, function () {
        return __generator(this, function (_a) {
            throw new Error('Function not implemented.');
        });
    }); },
};
axios.interceptors.response.use(function (response) { return response; }, function (error) { return __awaiter(void 0, void 0, void 0, function () {
    var originalRequest, refreshToken, newAccessToken, refreshError_1;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                originalRequest = error.config;
                if (!(((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 401 && !originalRequest._retry)) return [3 /*break*/, 6];
                originalRequest._retry = true;
                refreshToken = localStorage.getItem('refreshToken');
                if (!refreshToken) return [3 /*break*/, 5];
                _b.label = 1;
            case 1:
                _b.trys.push([1, 3, , 4]);
                return [4 /*yield*/, refreshAccessToken(refreshToken)];
            case 2:
                newAccessToken = _b.sent();
                localStorage.setItem('accessToken', newAccessToken);
                originalRequest.headers['Authorization'] = "Bearer ".concat(newAccessToken);
                return [2 /*return*/, axios(originalRequest)];
            case 3:
                refreshError_1 = _b.sent();
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                return [3 /*break*/, 4];
            case 4: return [3 /*break*/, 6];
            case 5:
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                _b.label = 6;
            case 6: return [2 /*return*/, Promise.reject(error)];
        }
    });
}); });
export default dataProvider;
