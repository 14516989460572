var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CardContent } from "@mui/material";
import { Datagrid, TextField } from "react-admin";
var FooterNavLinkText = function () {
    return (_jsxs(Box, __assign({ sx: { mb: 4 } }, { children: [_jsx(CardContent, { children: _jsx("h2", { children: "Footer NavLink Text" }) }), _jsxs(Datagrid, __assign({ rowClick: "edit" }, { children: [_jsx(TextField, { source: "language", label: "Language" }), _jsx(TextField, { source: "footer.links.ecosystem.text", label: "Footer Nav1" }), _jsx(TextField, { source: "footer.links.development.text", label: "Footer Nav2" }), _jsx(TextField, { source: "footer.links.blog.text", label: "Footer Nav3" }), _jsx(TextField, { source: "footer.links.learn.text", label: "Footer Nav4" }), _jsx(TextField, { source: "footer.links.docs.text", label: "Footer Nav5" }), _jsx(TextField, { source: "footer.links.github.text", label: "Footer Nav6" }), _jsx(TextField, { source: "footer.links.auditReport.text", label: "Footer Nav7" }), _jsx(TextField, { source: "footer.links.mediaKit.text", label: "Footer Nav8" }), _jsx(TextField, { source: "footer.links.privacyPolicy.text", label: "Footer Nav9" }), _jsx(TextField, { source: "footer.links.terms.text", label: "Footer Nav10" }), _jsx(TextField, { source: "footer.links.cookiePolicy.text", label: "Footer Nav11" }), _jsx(TextField, { source: "footer.links.contactUs.text", label: "Footer Nav12" })] }))] })));
};
export default FooterNavLinkText;
