import OtherButtonText from "../../admin/locale/list/otherButton";
import PlanetTextFirst from "../../admin/locale/list/planetTextFirst";
import PlanetTextSecond from "../../admin/locale/list/planetTextSecond";
import WidgetTextList from "../../admin/locale/list/widgetTextList";
import RoadmapText from "../../admin/locale/list/roadmapText";
import RoadmapTextIcons from "../../admin/locale/list/roadmapTextIcons";
import RoadmapActiveStep from "../../admin/locale/list/roadmapActiveStep";
import TrustedText from "../../admin/locale/list/trustedText";
import EcosystemText from "../../admin/locale/list/ecosystemText";
import EcosystemCardNameTextPart1 from "../../admin/locale/list/ecosystemCardNameTextPart1";
import EcosystemCardNameTextPart2 from "../../admin/locale/list/ecosystemCardNameTextPart2";
import EcosystemCardNameTextPart3 from "../../admin/locale/list/ecosystemCardNameTextPart3";
import EcosystemCardDesTextPart1 from "../../admin/locale/list/ecosystemCardDesTextPart1";
import EcosystemCardDesTextPart2 from "../../admin/locale/list/ecosystemCardDesTextPart2";
import EcosystemCardDesTextPart3 from "../../admin/locale/list/ecosystemCardDesTextPart3";
import EcosystemCardUsersTextPart1 from "../../admin/locale/list/ecosystemCardUsersTextPart1";
import EcosystemCardUsersTextPart2 from "../../admin/locale/list/ecosystemCardUsersTextPart2";
import EcosystemCardUsersTextPart3 from "../../admin/locale/list/ecosystemCardUsersTextPart3";
import EcosystemCardTVLTextPart1 from "../../admin/locale/list/ecosystemCardTVLTextPart1";
import EcosystemCardTVLTextPart2 from "../../admin/locale/list/ecosystemCardTVLTextPart2";
import EcosystemCardTVLTextPart3 from "../../admin/locale/list/ecosystemCardTVLTextPart3";
import EmailText from "../../admin/locale/list/emailText";
import NewsText from "../../admin/locale/list/newsText";
import FooterNavLinkText from "../../admin/locale/list/footerNavLinkText";
import FooterNavLinkLink from "../../admin/locale/list/footerNavLinkLink";
import FooterSocialLink from "../../admin/locale/list/footerSocialLink";
import FooterCopyrightText from "../../admin/locale/list/footerCopyrightText";
export var componentsLocaleList = [
    OtherButtonText,
    PlanetTextFirst,
    PlanetTextSecond,
    WidgetTextList,
    RoadmapText,
    RoadmapTextIcons,
    RoadmapActiveStep,
    TrustedText,
    EcosystemText,
    EcosystemCardNameTextPart1,
    EcosystemCardNameTextPart2,
    EcosystemCardNameTextPart3,
    EcosystemCardDesTextPart1,
    EcosystemCardDesTextPart2,
    EcosystemCardDesTextPart3,
    EcosystemCardUsersTextPart1,
    EcosystemCardUsersTextPart2,
    EcosystemCardUsersTextPart3,
    EcosystemCardTVLTextPart1,
    EcosystemCardTVLTextPart2,
    EcosystemCardTVLTextPart3,
    EmailText,
    NewsText,
    FooterNavLinkText,
    FooterNavLinkLink,
    FooterSocialLink,
    FooterCopyrightText,
];
