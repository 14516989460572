var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var PlanetBlockHeader = styled.h2(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    color: #ffffff;\n    font-weight: 400;\n    font-size: 72px;\n    text-align: center;\n    font-family: \"Syncopate\", sans-serif;\n    margin-bottom: 0;\n    @media (max-width: 1200px) {\n        font-size: 60px;\n    }\n\n    @media (max-width: 834px) {\n        font-size: 40px;\n    }\n\n    @media (max-width: 580px) {\n        font-size: 30px;\n    }\n"], ["\n    color: #ffffff;\n    font-weight: 400;\n    font-size: 72px;\n    text-align: center;\n    font-family: \"Syncopate\", sans-serif;\n    margin-bottom: 0;\n    @media (max-width: 1200px) {\n        font-size: 60px;\n    }\n\n    @media (max-width: 834px) {\n        font-size: 40px;\n    }\n\n    @media (max-width: 580px) {\n        font-size: 30px;\n    }\n"])));
export var PlanetBlockGradientText = styled.h3(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    position: relative;\n    font-size: 72px;\n    font-family: \"Syncopate\", sans-serif;\n    background-image: linear-gradient(45deg, #ffb801, #ff8e0a);\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n    background-size: 100%;\n    width: fit-content;\n    margin: 0 auto;\n    text-align: center;\n\n    &::before {\n        display: block;\n        position: absolute;\n        top: 0;\n        left: 0;\n        z-index: -1;\n        text-shadow: 0 3px 0 #b84d11;\n        content: \"", "\";\n        width: 100%;\n        height: 100%;\n        overflow: visible;\n    }\n\n    @media (max-width: 1200px) {\n        font-size: 60px;\n    }\n    @media (max-width: 834px) {\n        font-size: 40px;\n    }\n    @media (max-width: 580px) {\n        font-size: 30px;\n    }\n"], ["\n    position: relative;\n    font-size: 72px;\n    font-family: \"Syncopate\", sans-serif;\n    background-image: linear-gradient(45deg, #ffb801, #ff8e0a);\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n    background-size: 100%;\n    width: fit-content;\n    margin: 0 auto;\n    text-align: center;\n\n    &::before {\n        display: block;\n        position: absolute;\n        top: 0;\n        left: 0;\n        z-index: -1;\n        text-shadow: 0 3px 0 #b84d11;\n        content: \"", "\";\n        width: 100%;\n        height: 100%;\n        overflow: visible;\n    }\n\n    @media (max-width: 1200px) {\n        font-size: 60px;\n    }\n    @media (max-width: 834px) {\n        font-size: 40px;\n    }\n    @media (max-width: 580px) {\n        font-size: 30px;\n    }\n"])), function (props) { return typeof props.children === 'string' ? props.children : ''; });
export var PlanetBlockGradientTextContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  display: inline-block;\n  font-size: 72px;\n  font-family: \"Syncopate\", sans-serif;\n  text-align: center;\n  width: fit-content;\n  margin: 0 auto;\n    @media (max-width: 1200px) {\n        font-size: 60px;\n    }\n    @media (max-width: 834px) {\n        font-size: 40px;\n    }\n    @media (max-width: 580px) {\n        font-size: 30px;\n    }\n"], ["\n  position: relative;\n  display: inline-block;\n  font-size: 72px;\n  font-family: \"Syncopate\", sans-serif;\n  text-align: center;\n  width: fit-content;\n  margin: 0 auto;\n    @media (max-width: 1200px) {\n        font-size: 60px;\n    }\n    @media (max-width: 834px) {\n        font-size: 40px;\n    }\n    @media (max-width: 580px) {\n        font-size: 30px;\n    }\n"])));
export var GradientText = styled.h3(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background-image: linear-gradient(45deg, #ffb801, #ff8e0a);\n  -webkit-background-clip: text;\n  -webkit-text-fill-color: transparent;\n  position: relative;\n  margin-top: 10px;\n  margin-bottom: 10px;\n"], ["\n  background-image: linear-gradient(45deg, #ffb801, #ff8e0a);\n  -webkit-background-clip: text;\n  -webkit-text-fill-color: transparent;\n  position: relative;\n  margin-top: 10px;\n  margin-bottom: 10px;\n"])));
export var ShadowText = styled.h3(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  position: absolute;\n  top: -9px;\n  left: 0;\n  z-index: 0;\n  color: transparent;\n  text-shadow: 0 3px 0 #b84d11;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 10px;\n  margin-bottom: 10px;\n"], ["\n  position: absolute;\n  top: -9px;\n  left: 0;\n  z-index: 0;\n  color: transparent;\n  text-shadow: 0 3px 0 #b84d11;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 10px;\n  margin-bottom: 10px;\n"])));
export var PlanetBlockText = styled.h3(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    color: #d9d9e3;\n    font-family: \"Kodchasan\", sans-serif;\n    font-weight: 500;\n    font-size: 16px;\n    text-align: center;\n    width: 530px;\n    margin: 0 auto;\n    @media (max-width: 1200px) {\n        font-size: 14px;\n    }\n    @media (max-width: 580px) {\n        font-size: 12px;\n        width: 283px;\n    }\n"], ["\n    color: #d9d9e3;\n    font-family: \"Kodchasan\", sans-serif;\n    font-weight: 500;\n    font-size: 16px;\n    text-align: center;\n    width: 530px;\n    margin: 0 auto;\n    @media (max-width: 1200px) {\n        font-size: 14px;\n    }\n    @media (max-width: 580px) {\n        font-size: 12px;\n        width: 283px;\n    }\n"])));
export var ScrollToTop = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  position: fixed;\n  right: 20px;\n  bottom: 40px;\n  height: 30px;\n  font-size: 32px;\n  z-index: 5;\n  cursor: pointer;\n  color: black;\n"], ["\n  position: fixed;\n  right: 20px;\n  bottom: 40px;\n  height: 30px;\n  font-size: 32px;\n  z-index: 5;\n  cursor: pointer;\n  color: black;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
