var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CardContent } from "@mui/material";
import { Datagrid, TextField } from "react-admin";
var NewsText = function () {
    return (_jsxs(Box, __assign({ sx: { mb: 4 } }, { children: [_jsx(CardContent, { children: _jsx("h2", { children: "News Text" }) }), _jsxs(Datagrid, __assign({ rowClick: "edit" }, { children: [_jsx(TextField, { source: "language", label: "Language" }), _jsx(TextField, { source: "email_block.mobile_blog", label: "Mobile Blog" }), _jsx(TextField, { source: "email_block.latest_news", label: "Mobile Latest News" }), _jsx(TextField, { source: "email_block.news.quanta_news_data_1", label: "News Data1" }), _jsx(TextField, { source: "email_block.news.quanta_news_text_1", label: "News Text1" }), _jsx(TextField, { source: "email_block.news.quanta_news_data_2", label: "News Data2" }), _jsx(TextField, { source: "email_block.news.quanta_news_text_2", label: "News Text2" }), _jsx(TextField, { source: "email_block.news.quanta_news_data_3", label: "News Data3" }), _jsx(TextField, { source: "email_block.news.quanta_news_text_3", label: "News Text3" })] }))] })));
};
export default NewsText;
