var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CardContent } from "@mui/material";
import { Datagrid, TextField } from "react-admin";
var WidgetTextList = function () {
    return (_jsxs(Box, __assign({ sx: { mb: 4 } }, { children: [_jsx(CardContent, { children: _jsx("h2", { children: "Widget Text" }) }), _jsxs(Datagrid, { children: [_jsx(TextField, { source: "language", label: "Language" }), _jsx(TextField, { source: "widget[0].title", label: "Widget Title 1" }), _jsx(TextField, { source: "widget[1].title", label: "Widget Title 2" }), _jsx(TextField, { source: "widget[2].title", label: "Widget Title 3" }), _jsx(TextField, { source: "widget[3].title", label: "Widget Title 4" }), _jsx(TextField, { source: "widget[0].value", label: "Widget Value 1" }), _jsx(TextField, { source: "widget[1].value", label: "Widget Value 2" }), _jsx(TextField, { source: "widget[2].value", label: "Widget Value 3" }), _jsx(TextField, { source: "widget[3].value", label: "Widget Value 4" }), _jsx(TextField, { source: "widget[0].change30D", label: "Widget Change_30D 1" }), _jsx(TextField, { source: "widget[0].change90D", label: "Widget Change_90D 1" }), _jsx(TextField, { source: "widget[1].change30D", label: "Widget Change_30D 2" }), _jsx(TextField, { source: "widget[1].change90D", label: "Widget Change_90D 2" }), _jsx(TextField, { source: "widget[2].change30D", label: "Widget Change_30D 3" }), _jsx(TextField, { source: "widget[2].change90D", label: "Widget Change_90D 3" }), _jsx(TextField, { source: "widget[3].change30D", label: "Widget Change_30D 4" }), _jsx(TextField, { source: "widget[3].change90D", label: "Widget Change_90D 4" })] })] })));
};
export default WidgetTextList;
