var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Edit, SimpleForm, TextInput, ArrayInput, SimpleFormIterator, required, SelectInput, } from 'react-admin';
export var HeaderNavEdit = function (props) { return (_jsx(Edit, __assign({}, props, { children: _jsxs(SimpleForm, { children: [_jsx(TextInput, { source: "title", label: "Title", validate: required() }), _jsx(SelectInput, { source: "arrow", choices: [
                    { id: true, name: 'Yes' },
                    { id: false, name: 'No' }
                ] }), _jsx(ArrayInput, __assign({ source: "dropItems" }, { children: _jsxs(SimpleFormIterator, { children: [_jsx(TextInput, { source: "title", label: "Dropdown Item Title", validate: required() }), _jsx(TextInput, { source: "link", label: "Dropdown Item Link" })] }) }))] }) }))); };
