var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Button, TextField, Box, Typography, Paper, Alert } from '@mui/material';
import { useLogin } from 'react-admin';
import { authProvider } from '../admin/providers/authProvider';
var LoginPage = function () {
    var _a = useState(''), username = _a[0], setUsername = _a[1];
    var _b = useState(''), password = _b[0], setPassword = _b[1];
    var _c = useState(null), error = _c[0], setError = _c[1];
    var loginHook = useLogin();
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    setError(null);
                    return [4 /*yield*/, authProvider.login({ username: username, password: password })];
                case 2:
                    _a.sent();
                    loginHook({ username: username, password: password });
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    if (err_1 instanceof Error)
                        setError(err_1.message);
                    else
                        setError('An unknown error occurred.');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(Box, __assign({ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100vh", sx: {
            backgroundImage: "url(".concat(process.env.REACT_APP_API_URL, "/images/background-site.webp)"),
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundColor: '#0d0d0d',
            color: '#fff',
        } }, { children: _jsxs(Paper, __assign({ elevation: 3, sx: {
                padding: 4,
                borderRadius: 2,
                width: 350,
                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                boxShadow: '0px 4px 20px rgba(255, 165, 0, 0.3)',
            } }, { children: [_jsx(Typography, __assign({ variant: "h4", align: "center", gutterBottom: true, sx: {
                        color: '#ff9800',
                        textShadow: '0 0 10px #ff9800',
                        fontFamily: '"Orbitron", sans-serif',
                    } }, { children: "Admin Login" })), error && (_jsx(Alert, __assign({ severity: "error", sx: { mb: 2 } }, { children: error }))), _jsxs("form", __assign({ onSubmit: handleSubmit }, { children: [_jsx(TextField, { label: "Username", variant: "outlined", margin: "normal", fullWidth: true, value: username, onChange: function (e) { return setUsername(e.target.value); }, InputProps: {
                                sx: {
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                    color: '#fff',
                                    borderRadius: 1,
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#fff',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#ff9800',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#ff9800',
                                        boxShadow: '0 0 5px rgba(255, 152, 0, 0.5)',
                                    },
                                },
                            }, InputLabelProps: {
                                sx: { color: '#fff' },
                            } }), _jsx(TextField, { label: "Password", type: "password", variant: "outlined", margin: "normal", fullWidth: true, value: password, onChange: function (e) { return setPassword(e.target.value); }, InputProps: {
                                sx: {
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                    color: '#fff',
                                    borderRadius: 1,
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#fff',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#ff9800',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#ff9800',
                                        boxShadow: '0 0 5px rgba(255, 152, 0, 0.5)',
                                    },
                                },
                            }, InputLabelProps: {
                                sx: { color: '#fff' },
                            } }), _jsx(Button, __assign({ type: "submit", variant: "contained", color: "primary", fullWidth: true, sx: { mt: 2, backgroundColor: '#ff9800', '&:hover': { backgroundColor: '#e68900' } } }, { children: "Login" }))] }))] })) })));
};
export default LoginPage;
