var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Edit, SimpleForm, TextField, Button, useRecordContext, DateField, FunctionField } from 'react-admin';
import { TextField as TextFieldMUI, Box, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import { CustomToolbar } from "./documentsConfirmToolbar";
import axios from "axios";
import CryptoJS from "crypto-js";
var apiUrl = process.env.REACT_APP_API_URL;
var EditA = function () {
    var record = useRecordContext();
    var _a = useState(''), text = _a[0], setText = _a[1]; // Состояние для хранения текста
    var handleChange = function (event) {
        setText(event.target.value);
    };
    var handleSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var secretKey, encryptedEmail, encryptedMessage, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!record) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    secretKey = process.env.ENCRYPTION_KEY || '';
                    encryptedEmail = CryptoJS.AES.encrypt(record.email, secretKey).toString();
                    encryptedMessage = CryptoJS.AES.encrypt(text, secretKey).toString();
                    return [4 /*yield*/, axios.post("".concat(process.env.REACT_APP_API_URL, "/send-user-email"), {
                            email: encryptedEmail,
                            message: encryptedMessage
                        })];
                case 2:
                    _a.sent();
                    alert('Message sent successfully!');
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.error('Error sending message:', error_1);
                    alert('Error sending message');
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Grid, __assign({ item: true, xs: 12 }, { children: [_jsx(Typography, __assign({ sx: { fontWeight: 'bold' } }, { children: "Notificate user:" })), _jsxs(Box, __assign({ sx: {
                    mt: 1,
                    mb: 1,
                    display: 'flex',
                    gap: '12px',
                    alignItems: 'flex-end',
                    width: '100%',
                } }, { children: [_jsx(TextFieldMUI, { value: text, onChange: handleChange, variant: "outlined", label: "Enter Text", fullWidth: true, multiline: true, rows: 4 }), _jsx(Button, { label: "Send", variant: "contained", color: "primary", onClick: handleSubmit, sx: { height: '100%' } })] }))] })));
};
export var DocumentsConfirmEdit = function (props) {
    var _a = useState(''), text = _a[0], setText = _a[1]; // Хранение текста в состоянии
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var _c = useState(null), currentImage = _c[0], setCurrentImage = _c[1];
    var handleChange = function (event) {
        setText(event.target.value);
    };
    var handleImageClick = function (imageUrl) {
        setCurrentImage(imageUrl);
        setOpen(true);
    };
    var handleClose = function () {
        setOpen(false);
        setCurrentImage(null);
    };
    return (_jsx(Edit, __assign({}, props, { children: _jsxs(SimpleForm, __assign({ toolbar: _jsx(CustomToolbar, {}) }, { children: [_jsxs(Grid, __assign({ container: true, spacing: 3 }, { children: [_jsxs(Grid, __assign({ item: true, xs: 12, sm: 6 }, { children: [_jsx(Typography, __assign({ variant: "h6", gutterBottom: true }, { children: "Personal Information" })), _jsxs(Box, __assign({ sx: {
                                        mb: 1,
                                        display: 'flex',
                                        gap: '12px',
                                        alignItems: 'center'
                                    } }, { children: [_jsx(Typography, __assign({ sx: { fontWeight: "bold" } }, { children: "Email:" })), _jsx(TextField, { fontSize: 16, source: "email", label: "Email" })] })), _jsxs(Box, __assign({ sx: {
                                        mb: 1,
                                        display: 'flex',
                                        gap: '12px',
                                        alignItems: 'center'
                                    } }, { children: [_jsx(Typography, __assign({ sx: { fontWeight: "bold" } }, { children: "Full Name:" })), _jsx(TextField, { fontSize: 16, source: "__details__.firstName", label: "First Name" }), _jsx(TextField, { fontSize: 16, source: "__details__.lastName", label: "Last Name" })] })), _jsxs(Box, __assign({ sx: {
                                        mb: 1,
                                        display: 'flex',
                                        gap: '12px',
                                        alignItems: 'center'
                                    } }, { children: [_jsx(Typography, __assign({ sx: { fontWeight: "bold" } }, { children: "Birth Date:" })), _jsx(DateField, { source: "__details__.birthDate", label: "Birth Date" })] })), _jsxs(Box, __assign({ sx: {
                                        mb: 1,
                                        display: 'flex',
                                        gap: '12px',
                                        alignItems: 'center'
                                    } }, { children: [_jsx(Typography, __assign({ sx: { fontWeight: "bold" } }, { children: "Address:" })), _jsx(TextField, { fontSize: 16, source: "__details__.address", label: "Address" })] })), _jsxs(Box, __assign({ sx: {
                                        mb: 1,
                                        display: 'flex',
                                        gap: '12px',
                                        alignItems: 'center'
                                    } }, { children: [_jsx(Typography, __assign({ sx: { fontWeight: "bold" } }, { children: "Citizenship:" })), _jsx(TextField, { fontSize: 16, source: "__details__.citizenship", label: "Citizenship" })] })), _jsxs(Box, __assign({ sx: {
                                        mb: 1,
                                        display: 'flex',
                                        gap: '12px',
                                        alignItems: 'center'
                                    } }, { children: [_jsx(Typography, __assign({ sx: { fontWeight: "bold" } }, { children: "City:" })), _jsx(TextField, { fontSize: 16, source: "__details__.city", label: "City" })] })), _jsxs(Box, __assign({ sx: {
                                        mb: 1,
                                        display: 'flex',
                                        gap: '12px',
                                        alignItems: 'center'
                                    } }, { children: [_jsx(Typography, __assign({ sx: { fontWeight: "bold" } }, { children: "Postal code:" })), _jsx(TextField, { fontSize: 16, source: "__details__.postalCode", label: "PostalCode" })] })), _jsxs(Box, __assign({ sx: {
                                        mb: 1,
                                        display: 'flex',
                                        gap: '12px',
                                        alignItems: 'center'
                                    } }, { children: [_jsx(Typography, __assign({ sx: { fontWeight: "bold" } }, { children: "Region:" })), _jsx(TextField, { fontSize: 16, source: "__details__.region", label: "Region" })] })), _jsxs(Box, __assign({ sx: {
                                        mb: 1,
                                        display: 'flex',
                                        gap: '12px',
                                        alignItems: 'center'
                                    } }, { children: [_jsx(Typography, __assign({ sx: { fontWeight: "bold" } }, { children: "Residency:" })), _jsx(TextField, { fontSize: 16, source: "__details__.residency", label: "Residency" })] })), _jsxs(Box, __assign({ sx: {
                                        mb: 1,
                                        display: 'flex',
                                        gap: '12px',
                                        alignItems: 'center'
                                    } }, { children: [_jsx(Typography, __assign({ sx: { fontWeight: "bold" } }, { children: "Selected country:" })), _jsx(TextField, { fontSize: 16, source: "__details__.selectedCountry", label: "SelectedCountry" })] }))] })), _jsxs(Grid, __assign({ item: true, xs: 12, sm: 6 }, { children: [_jsx(Typography, __assign({ variant: "h6", gutterBottom: true }, { children: "Document Details" })), _jsxs(Box, __assign({ sx: {
                                        mb: 1,
                                        display: 'flex',
                                        gap: '12px',
                                        alignItems: 'center'
                                    } }, { children: [_jsx(Typography, __assign({ sx: { fontWeight: "bold" } }, { children: "Document type:" })), _jsx(TextField, { fontSize: 16, source: "__document__.document", label: "Document Type" })] })), _jsxs(Box, __assign({ sx: {
                                        mb: 1,
                                        display: 'flex',
                                        gap: '12px',
                                        alignItems: 'center'
                                    } }, { children: [_jsx(Typography, __assign({ sx: { fontWeight: "bold" } }, { children: "Document country:" })), _jsx(TextField, { fontSize: 16, source: "__document__.documentCountry", label: "Document Country" })] }))] })), _jsxs(Grid, __assign({ item: true, xs: 12 }, { children: [_jsx(Typography, __assign({ variant: "h6", gutterBottom: true }, { children: "Images" })), _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 4 }, { children: _jsx(FunctionField, { label: "Front Side Image", render: function (record) {
                                                    return record && record.__document__ ? (_jsx("img", { src: "".concat(process.env.REACT_APP_API_URL, "/user/image/").concat(record.__document__.id, "/docImageFront"), alt: "Front Side", style: {
                                                            maxWidth: '100%',
                                                            maxHeight: 200,
                                                            cursor: 'pointer'
                                                        }, onClick: function () {
                                                            return handleImageClick("".concat(process.env.REACT_APP_API_URL, "/user/image/").concat(record.__document__.id, "/docImageFront"));
                                                        } })) : ('No image available');
                                                } }) })), _jsx(Grid, __assign({ item: true, xs: 4 }, { children: _jsx(FunctionField, { label: "Back Side Image", render: function (record) {
                                                    return record && record.__document__ ? (_jsx("img", { src: "".concat(process.env.REACT_APP_API_URL, "/user/image/").concat(record.__document__.id, "/docImageBack"), alt: "Back Side", style: {
                                                            maxWidth: '100%',
                                                            maxHeight: 200,
                                                            cursor: 'pointer'
                                                        }, onClick: function () {
                                                            return handleImageClick("".concat(process.env.REACT_APP_API_URL, "/user/image/").concat(record.__document__.id, "/docImageBack"));
                                                        } })) : ('No image available');
                                                } }) })), _jsx(Grid, __assign({ item: true, xs: 4 }, { children: _jsx(FunctionField, { label: "Person Image", render: function (record) {
                                                    return record && record.__document__ ? (_jsx("img", { src: "".concat(process.env.REACT_APP_API_URL, "/user/image/").concat(record.__document__.id, "/personImage"), alt: "Person", style: {
                                                            maxWidth: '100%',
                                                            maxHeight: 200,
                                                            cursor: 'pointer'
                                                        }, onClick: function () {
                                                            return handleImageClick("".concat(process.env.REACT_APP_API_URL, "/user/image/").concat(record.__document__.id, "/personImage"));
                                                        } })) : ('No image available');
                                                } }) }))] }))] })), _jsx(EditA, {})] })), _jsx(Dialog, __assign({ open: open, onClose: handleClose, maxWidth: "lg" }, { children: _jsx(DialogContent, { children: currentImage && (_jsx("img", { src: currentImage, alt: "Full Screen", style: {
                                margin: '0 auto',
                                width: 'auto',
                                height: '100%'
                            } })) }) }))] })) })));
};
