var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CardContent } from "@mui/material";
import { Datagrid, TextField } from "react-admin";
var EmailText = function () {
    return (_jsxs(Box, __assign({ sx: { mb: 4 } }, { children: [_jsx(CardContent, { children: _jsx("h2", { children: "Email Text" }) }), _jsxs(Datagrid, __assign({ rowClick: "edit" }, { children: [_jsx(TextField, { source: "language", label: "Language" }), _jsx(TextField, { source: "email_block.sign_up", label: "News SignUp" }), _jsx(TextField, { source: "email_block.description.part1", label: "News Part1" }), _jsx(TextField, { source: "email_block.description.part2", label: "News Part2" }), _jsx(TextField, { source: "email_block.description.part3", label: "News Part3" }), _jsx(TextField, { source: "email_block.description.full", label: "News Full" }), _jsx(TextField, { source: "email_block.placeholder", label: "Placeholder" })] }))] })));
};
export default EmailText;
