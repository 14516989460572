var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Datagrid, EditButton, FunctionField, List, Pagination, TextField } from 'react-admin';
export var ImageList = function (props) { return (_jsx(List, __assign({}, props, { pagination: _jsx(Pagination, {}) }, { children: _jsxs(Datagrid, { children: [_jsx(TextField, { source: "filename", label: "Filename" }), _jsx(FunctionField, { label: "Image", render: function (record) { return (_jsx("img", { src: "".concat(process.env.REACT_APP_API_URL, "/images/").concat(record.filename), alt: record.filename, style: { maxWidth: 100, maxHeight: 100 } })); } }), _jsx(EditButton, {})] }) }))); };
