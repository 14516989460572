import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainPage from "../pages/MainPage";
import NotFoundPage from "../pages/NotFoundPage";
import NavigationPage from "../pages/NavigationPage";
import AdminPanel from '../pages/AdminPanel';
var AppRouter = function (_a) {
    var currentLocale = _a.currentLocale, onLocaleChange = _a.onLocaleChange;
    return (_jsx(BrowserRouter, { children: _jsxs(Routes, { children: [_jsx(Route, { path: '/', element: _jsx(MainPage, { currentLocale: currentLocale, onLocaleChange: onLocaleChange }) }), _jsx(Route, { path: '/navigation', element: _jsx(NavigationPage, {}) }), _jsx(Route, { path: '*', element: _jsx(NotFoundPage, { currentLocale: currentLocale, onLocaleChange: onLocaleChange }) }), _jsx(Route, { path: '/admin/*', element: _jsx(AdminPanel, {}) })] }) }));
};
export default AppRouter;
