var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CardContent } from "@mui/material";
import { Datagrid, TextField } from "react-admin";
var PlanetTextSecond = function () {
    return (_jsxs(Box, __assign({ sx: { mb: 4 } }, { children: [_jsx(CardContent, { children: _jsx("h2", { children: "Planet Text Second" }) }), _jsxs(Datagrid, { children: [_jsx(TextField, { source: "language", label: "Language" }), _jsx(TextField, { source: "planet_block.title2", label: "Planet Block Title2" }), _jsx(TextField, { source: "planet_block.subtitle2", label: "Planet Block Subtitle2" }), _jsx(TextField, { source: "planet_block.text2", label: "Planet Block Text2" }), _jsx(TextField, { source: "planet_block.title3", label: "Planet Block Title3" }), _jsx(TextField, { source: "planet_block.subtitle3", label: "Planet Block Subtitle3" }), _jsx(TextField, { source: "planet_block.text3", label: "Planet Block Text3" })] })] })));
};
export default PlanetTextSecond;
