var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Admin, radiantDarkTheme, radiantLightTheme, Resource } from 'react-admin';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { HeaderNavList } from '../admin/navigation/headerList';
import { HeaderNavEdit } from '../admin/navigation/headerEdit';
import dataProvider from '../admin/providers/dataProvider';
import LoginPage from './AdminLogin';
import { authProvider } from '../admin/providers/authProvider';
import { Dashboard } from '../admin/dashboard/Dashboard';
import { ImageEdit } from '../admin/images/imageEdit';
import { ImageList } from '../admin/images/imageList';
import { LocaleEdit } from '../admin/locale/edit/localeEdit';
import { LocaleList } from '../admin/locale/list/localeList';
import { DocumentsConfirmList } from "../admin/documentsConfirm/documentsConfirmList";
import { DocumentsConfirmEdit } from "../admin/documentsConfirm/documentsConfirmEdit";
var theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2',
        },
        secondary: {
            main: '#dc004e',
        },
    },
});
var AdminPanel = function () { return (_jsx(ThemeProvider, __assign({ theme: theme }, { children: _jsxs(Admin, __assign({ authProvider: authProvider, dataProvider: dataProvider, dashboard: Dashboard, loginPage: LoginPage, theme: radiantLightTheme, darkTheme: radiantDarkTheme, basename: "/admin" }, { children: [_jsx(Resource, { name: "nav-items", list: HeaderNavList, edit: HeaderNavEdit }), _jsx(Resource, { name: "localization", list: LocaleList, edit: LocaleEdit }), _jsx(Resource, { name: "images", list: ImageList, edit: ImageEdit }), _jsx(Resource, { name: "document-Confirm", list: DocumentsConfirmList, edit: DocumentsConfirmEdit })] })) }))); };
export default AdminPanel;
