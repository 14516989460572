var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { ButtonWrapper, NavigationButton, NavigationPageWrapper } from "../assets/styles/navigationPage/navigationPage";
import { DevPageState } from "../types/navigationPage/DevPageState";
var NavigationPage = function () {
    return (_jsx(NavigationPageWrapper, { children: _jsxs(ButtonWrapper, { children: [_jsx(NavigationButton, __assign({ href: '/main', "$state": DevPageState.NoStarted }, { children: "\u0413\u043B\u0430\u0432\u043D\u0430\u044F" })), _jsx(NavigationButton, __assign({ href: '/profile', "$state": DevPageState.InProcess }, { children: "\u041F\u0440\u043E\u0444\u0438\u043B\u044C" })), _jsx(NavigationButton, __assign({ href: '/collection', "$state": DevPageState.NoStarted }, { children: "\u041A\u043E\u043B\u0435\u043A\u0446\u0438\u044F" })), _jsx(NavigationButton, __assign({ href: '/' }, { children: "\u2190\u041D\u0430\u0437\u0430\u0434" }))] }) }));
};
export default NavigationPage;
