var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CardContent } from "@mui/material";
import { Datagrid, TextField } from "react-admin";
var EcosystemCardNameTextPart2 = function () {
    return (_jsxs(Box, __assign({ sx: { mb: 4 } }, { children: [_jsx(CardContent, { children: _jsx("h2", { children: "Ecosystem Card Name Text Part2" }) }), _jsxs(Datagrid, { children: [_jsx(TextField, { source: "language", label: "Language" }), _jsx(TextField, { source: "ecosystem_block.contributors.part2_block[0].name", label: "Ecosystem Name5" }), _jsx(TextField, { source: "ecosystem_block.contributors.part2_block[1].name", label: "Ecosystem Name6" }), _jsx(TextField, { source: "ecosystem_block.contributors.part2_block[2].name", label: "Ecosystem Name7" }), _jsx(TextField, { source: "ecosystem_block.contributors.part2_block[3].name", label: "Ecosystem Name8" })] })] })));
};
export default EcosystemCardNameTextPart2;
