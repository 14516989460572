var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CardContent } from "@mui/material";
import { Datagrid, TextField } from "react-admin";
var EcosystemCardUsersTextPart3 = function () {
    return (_jsxs(Box, __assign({ sx: { mb: 4 } }, { children: [_jsx(CardContent, { children: _jsx("h2", { children: "Ecosystem Card Users Text Part3" }) }), _jsxs(Datagrid, { children: [_jsx(TextField, { source: "language", label: "Language" }), _jsx(TextField, { source: "ecosystem_block.contributors.part3_block[0].users", label: "Ecosystem Users9" }), _jsx(TextField, { source: "ecosystem_block.contributors.part3_block[1].users", label: "Ecosystem Users10" }), _jsx(TextField, { source: "ecosystem_block.contributors.part3_block[2].users", label: "Ecosystem Users11" }), _jsx(TextField, { source: "ecosystem_block.contributors.part3_block[3].users", label: "Ecosystem Users12" })] })] })));
};
export default EcosystemCardUsersTextPart3;
