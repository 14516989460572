var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Suspense } from "react";
import { GlobalContainer, OverflowContainer } from "../assets/styles/mainPageStyles";
import { ScrollToTop } from "../assets/styles/planetBlockV2/planetBlock.style";
var Header = React.lazy(function () { return import("../components/mainPage/header/Header"); });
var PlanetBlock = React.lazy(function () { return import("../components/mainPage/planetBlock/PlanetBlock"); });
var WidgetBlock = React.lazy(function () { return import("../components/mainPage/widgetBlock/WidgetBlock"); });
var TrustedByComponent = React.lazy(function () { return import("../components/mainPage/trustedBy/TrustedBy"); });
var RoadMap = React.lazy(function () { return import("../components/mainPage/roadmap/Roadmap"); });
var QuantaNews = React.lazy(function () { return import("../components/mainPage/quantaNews/QuantaNews"); });
var Footer = React.lazy(function () { return import("../components/mainPage/footer/Footer"); });
var QuantaEcosystemContainer = React.lazy(function () { return import("../components/mainPage/quantaEcosystem/QuantaEcosystemContainer"); });
var MainPage = function (_a) {
    var currentLocale = _a.currentLocale, onLocaleChange = _a.onLocaleChange;
    return (_jsx(Suspense, __assign({ fallback: null }, { children: _jsxs(GlobalContainer, { children: [_jsx(Header, { currentLocale: currentLocale, onLocaleChange: onLocaleChange }), _jsx(PlanetBlock, {}), _jsx(OverflowContainer, { children: _jsx(WidgetBlock, {}) }), _jsx(OverflowContainer, { children: _jsx(TrustedByComponent, {}) }), _jsx(OverflowContainer, { children: _jsx(QuantaEcosystemContainer, {}) }), _jsx(OverflowContainer, { children: _jsx(RoadMap, {}) }), _jsx(OverflowContainer, { children: _jsx(QuantaNews, {}) }), _jsx(ScrollToTop, {}), _jsx(OverflowContainer, { children: _jsx(Footer, {}) })] }) })));
};
export default MainPage;
