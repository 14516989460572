var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Divider, Box } from "@mui/material";
import { List } from "react-admin";
import { componentsLocaleList } from "../../../constants/adminConstants/componentsLocaleList";
export var LocaleList = function (props) {
    return (_jsx(List, __assign({}, props, { children: componentsLocaleList.map(function (Component, index) { return (_jsxs(Box, { children: [_jsx(Component, {}), _jsx(Divider, { sx: { my: 2 } })] }, index)); }) })));
};
